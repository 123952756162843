<template>
  <div class="career">
    <!-- HERO -->
    <div class="career__hero">
      <div class="container">
        <div class="career__info">
          <div class="career__logo" :class="{ 'is-kz': $i18n.locale === 'kz' }">
            {{ $t("growTogether") }}
          </div>
          <h1 class="career__title">
            {{ $t("buildCareer") }}
          </h1>
          <div class="career__subtitle">
            {{ $t("leaveRequest") }}
          </div>
        </div>

        <a class="career__btn" @click="scrollToVacancy">
          {{ $t("allVacancies") }}
        </a>

        <!-- <div class="career__images">
          <img
            src="@/assets/img/magnum-career/long.png"
            alt="career image"
            class="career__img-long"
          />
          <div class="career__images-wrapper">
            <img
              src="@/assets/img/magnum-career/top.png"
              alt="career image"
              class="career__img-top"
            />
            <img
              src="@/assets/img/magnum-career/bottom.png"
              alt="career image"
              class="career__img-bottom"
            />
          </div>
        </div> -->
      </div>
    </div>

    <!-- COME -->
    <div class="career-come">
      <div class="container">
        <div class="career-come__info">
          <h2 class="career-come__title big-title">
            {{ $t("createRetail") }}
          </h2>
          <p class="career-come__descr">
            {{ $t("WeCreateStores") }}<br /><br />
            {{ $t("internationalTeam") }}
          </p>
        </div>

        <div class="career-come__img">
          <img
            src="@/assets/img/magnum-career/come.png"
            alt="career come image"
          />
        </div>
      </div>
    </div>

    <!-- WARRANTY -->
    <!-- <div class="career-warranty">
      <div class="container">
        <div class="career-warranty__info">
          <h2 class="career-warranty__title big-title">
            {{ $t("we") }}
            <span> {{ $t("guarantee") }}</span>
            {{ $t("toYou") }}
          </h2>
          <ul class="career-warranty__items">
            <li class="career-warranty__item">
              {{ $t("stableSalary") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("freeMeals") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("paidInternship") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("freeHealthBook") }}
            </li>
            <li class="career-warranty__item">
              {{ $t("travelPayment") }}
            </li>
          </ul>
        </div>

        <div class="career-warranty__img">
          <img
            src="@/assets/img/magnum-career/warranty.png"
            alt="warranty image"
          />
        </div>
      </div>
    </div> -->

    <!-- ADVANTAGES -->
    <div class="career-advantages">
      <div class="container">
        <h2 class="career-advantages__title" v-html="$t('ourAdvantages')"></h2>
        <div class="career-advantages__content">
          <ul class="career-advantages__items">
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_2") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_3") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_4") }}
            </li>
          </ul>
          <ul class="career-advantages__items">
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_5") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_6") }}
            </li>
            <li class="career-advantages__item">
              {{ $t("vacancyList.item_7") }}
            </li>
          </ul>
        </div>
        <a href="#vacancy" class="career-advantages__btn">
          {{ $t("allVacancies") }}
        </a>
      </div>
    </div>

    <!-- CHOICE -->
    <div class="about__numbers career-numbers">
      <div class="container">
        <h2 class="about__numbers-title">
          <span> {{ $t("why") }}</span>
          {{ $t("chooseMagnum") }}
        </h2>

        <div class="about__slider career-slider">
          <div class="about__slide">
            <div class="about__slide-title">
              <span>№1</span> {{ $t("tradeNet") }}
            </div>
            <div class="about__slide-descr">
              <!-- {{ $t("retailNetwork") }} -->
              {{ $t("nowUZ") }}
            </div>
          </div>
          <div class="about__slide">
            <div v-html="$t('firstTwo')" class="about__slide-title"></div>
            <div class="about__slide-descr">{{ $t("openInTash") }}</div>
            <!-- <div class="about__slide-title">
              {{ $t("branches") }}
            </div>
            <div class="about__slide-descr">
              {{ $t("openCities") }}
            </div> -->
          </div>
          <div class="about__slide">
            <div class="about__slide-title">
              <span class="number">700</span> {{ $t("employees") }}
            </div>
            <div class="about__slide-descr">
              {{ $t("workIn") }}
            </div>
            <!-- <div class="about__slide-title">
              {{ $t("employeePromotionsNumber") }}
            </div>
            <div class="about__slide-descr">
              {{ $t("employeePromotions") }}
            </div> -->
          </div>
          <div class="about__slide">
            <div class="about__slide-title">
              <span class="number">1100</span> {{ $t("provider") }}
            </div>
            <div class="about__slide-descr">{{ $t("withMagnum") }}</div>
            <!-- <div class="about__slide-title">13 500</div>
            <div class="about__slide-descr">
              {{ $t("manConstantly") }}
            </div> -->
          </div>
          <!-- <div class="about__slide">
            <div class="about__slide-title">
              {{ $t("employeeTrainedNumber") }}
            </div>
            <div class="about__slide-descr">
              {{ $t("employeeTrained") }}
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <vacancy-block id="vacancy" />
    <!-- CAREER -->
  </div>
</template>

<script>
export default {
  name: "MagnumCareer",
  components: {
    VacancyBlock: () => import("@/components/vacancy/VacancyBlock.vue"),
  },
  methods: {
    scrollToVacancy() {
      document.querySelector("#vacancy").scrollIntoView();
    },
  },
};
</script>

<style scoped>
.about__slide {
  margin-right: 0;
}
.about__slide-title {
  font-size: 22px;
}
.about__slide-descr {
  max-width: none;
  font-size: 18px;
}
</style>
